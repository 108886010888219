const Nav = () => {
    return (
        <nav className='nav py-4'>
            <div className="container">
                <div className="flex justify-between items-center">
                    <h2 className='text-white text-[25px] bold'>
                        <a href="/" className="text-white text-[25px] bold">
                            Time Capsule
                        </a>
                    </h2>
                </div>
            </div>
        </nav>
    )
}
export default Nav