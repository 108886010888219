import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import MailSent from "../mail-sent";
import { DatePicker } from "antd";
import axios from 'axios';

const CraftMail = () => {
  const [mailSent, setMailSent] = useState(false);
  const [emailBody, setEmailBody] = useState("");
  const [jour, setJour] = useState("");
  const [validInputs] = useState({
    email: true,
  });

  const handleSubmitForm = (e) => {
    e.preventDefault();

    // Check for empty inputs
    const form = e.target;
    const hour = form.hour.value.trim();
    const minute = form.minute.value.trim();

    const email = form.email.value.trim();

    if (!hour || !minute || !email || !jour) {
      alert("Please fill out all fields");
      return;
    }

    setMailSent(true);

    const data = {
      hour,
      minute,
      jour,
      email,
      emailBody,
    };

    console.log(data);
    console.log(process.env.REACT_APP_API_URL)
    
    axios.post(process.env.REACT_APP_API_URL, data)
      .then(response => {
        console.log('API Response:', response.data);
      })
      .catch(error => {
        console.error('Error making API request:', error.message);
      });
  };

  const handleAgain = () => {
    setMailSent(false);
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (
        e.keyCode === 8 ||
        e.keyCode === 9 ||
        e.keyCode === 37 ||
        e.keyCode === 39 ||
        e.keyCode === 46
      ) {
        return true;
      } else if (e.keyCode < 48 || e.keyCode > 57) {
        e.preventDefault();
      }
    };

    const handleKeyUpHour = (e) => {
      if (e.target.value > 23) {
        e.target.value = 23;
      }
    };

    const handleKeyUpMin = (e) => {
      if (e.target.value > 59) {
        e.target.value = 59;
      }
    };

    const numberOnly = document.querySelectorAll(".number-only");
    numberOnly.forEach((item) => {
      item.addEventListener("keydown", handleKeyDown);
    });

    const hour = document.querySelector(".hour");
    hour.addEventListener("keyup", handleKeyUpHour);

    const min = document.querySelector(".min");
    min.addEventListener("keyup", handleKeyUpMin);

    // Cleanup event listeners
    return () => {
      numberOnly.forEach((item) => {
        item.removeEventListener("keydown", handleKeyDown);
      });
      hour.removeEventListener("keyup", handleKeyUpHour);
      min.removeEventListener("keyup", handleKeyUpMin);
    };
  }, []);

  const onChange = (date, dateString) => {
    const currentDate = new Date();
    const selectedDate = new Date(dateString);

    setJour(dateString);
  };

  return (
    <div className="craft-mail pb-[30px]">
      <div className="container">
        <div className="card bg-black rounded-[12px] md:px-[45px] px-[15px] md:py-[30px] py-[15px]">
          <h2 className="text-[30px] medium text-white ">Craft your email</h2>

          <div className="line bg-white h-[1px] w-full my-5"></div>

          {mailSent ? (
            <MailSent handleAgain={handleAgain} />
          ) : (
            <form action="" onSubmit={handleSubmitForm}>
              <div className="flex mb-[35px] justify-start items-center flex-wrap gap-6">
                <div className="item">
                  <p className="md:text-xl text-sm text-white"> Time <span style={{ fontSize: '0.5em' }}>(UTC +0)</span> </p>
                  <div className="flex mt-2 justify-between items-center w-[145px]">
                    <input
                      type="text"
                      className="w-[60px] h-[60px] text-center text-white rounded-[3px] bg-[#111111] number-only hour placeholder:text-white md:text-xl text-sm"
                      placeholder="--"
                      name="hour"
                      id=""
                    />
                    <span className="text-white md:text-xl text-sm">:</span>
                    <input
                      type="text"
                      className="w-[60px] h-[60px] text-center text-white rounded-[3px] bg-[#111111] number-only min placeholder:text-white md:text-xl text-sm"
                      placeholder="--"
                      name="minute"
                      id=""
                    />
                  </div>
                </div>
                <div className="item">
                  <p className="md:text-xl text-sm text-white">Day</p>
                  <div className="flex mt-2 justify-between items-center w-[180px]">
                    <DatePicker
                      placeholder="--"
                      className="w-full appearance-none h-[60px] text-left px-[16px] rounded-[3px] bg-[#111111] placeholder:text-white md:text-xl text-sm border-none text-white"
                      onChange={onChange}
                    />
                  </div>
                </div>

              </div>

              <div className="p-[20px] mb-[35px] bg-[#111111]">
                <CKEditor
                  editor={ClassicEditor}
                  // hide image upload tab
                  config={{
                    ckfinder: {
                      uploadUrl:
                        "/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Files&responseType=json",
                    },
                    toolbar: {
                      items: [
                        "heading",
                        "|",
                        "bold",
                        "italic",
                      ],
                    },
                    language: "en",
                    image: {
                      toolbar: [
                        "imageTextAlternative",
                        "imageStyle:full",
                        "imageStyle:side",
                      ],
                    },
                    table: {
                      contentToolbar: [
                        "tableColumn",
                        "tableRow",
                        "mergeTableCells",
                      ],
                    },
                    licenseKey: "",
                  }}
                  className="bg-black"
                  data="<p>Hello !</p><br />Feel free to express yourself here and encapsule your thoughts !<br /><br />Best - <br />"
                  onReady={(editor) => {
                    console.log(
                      "Happy writing !",
                      editor
                    );
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    console.log({ event, editor, data });
                    setEmailBody(data);
                  }}
                />
              </div>

              <div className="flex flex-wrap md:gap-0 gap-3 justify-between items-end">
              <div className="email lg:w-[40%] md:w-1/2 w-full">
                <p className="md:text-xl text-sm text-white">Email</p>
                <div className="flex mt-2 justify-between items-center w-full">
                  <input
                    type="email" // Set the type to "email"
                    className={`w-full h-[60px] text-left px-[16px] text-white rounded-[3px] bg-[#111111] placeholder:text-white md:text-xl text-sm ${
                      validInputs.email ? "border-green-500" : "border-red-500"
                    }`}
                    placeholder="Example@gmail.com"
                    name="email"
                    id=""
                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                    required // Add the required attribute to enforce non-empty input
                  />
                </div>
              </div>
                <button className="btn bg-[#FF6330] md:px-[40px] px-[20px] md:py-[20px] py-[10px] text-white rounded-[12px]">
                  Send
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default CraftMail;
