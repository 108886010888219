import React from 'react'
import Nav from '../../components/nav'
import TextColumn from '../../components/text-columns'
import CraftMail from '../../components/craft-mail'
import ImageColumn from '../../components/image-columns'
import CopyRight from '../../components/copyright'

const Home = () => {
    return (
<div>
    <header>
        <Nav />
    </header>
    <main>
        <section>
            <TextColumn />
        </section>
        <section>
            <CraftMail />
        </section>
        <section>
            <ImageColumn />
        </section>
    </main>
    <footer>
        <CopyRight />
    </footer>
</div>
    )
}

export default Home