import React from 'react';
import { Helmet } from 'react-helmet';

const TextColumn = () => {
    return (
        <section className='text-column py-[80px]'>
            <Helmet>
                <title>Send Emails to Your Future Self</title>
                <meta name="description" content="Explore the concept of sending emails to your future self. Discover the importance and benefits of this unique practice." />
            </Helmet>
            <div className="container">
                <div className="flex lg:flex-row flex-col lg:gap-0 gap-5 justify-between items-start">
                    <div className="lg:w-[60%] w-full">
                        <h1 className='text-white lg:text-[62px] md:text-[40px] text-[30px] font-bold font-serif'>
                            Send emails to <br />Your Future Self. <br />
                        </h1>
                        <p className="text-gray-300 text-lg">
                            Discover the unique practice of sending emails to your future self and explore the benefits it can bring to your personal and professional development.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default TextColumn;