import React from 'react';
import { Helmet } from 'react-helmet';
import man from "../../assets/images/again.png";

const ImageColumn = () => {
    return (
        <section className='image-column pb-[100px] pt-[50px]'>
            <Helmet>
                <title>About Us - TimeCapsule Email</title>
                <meta name="description" content="Welcome to TimeCapsule Email, where we foster self-reflection, personal growth, and mental health through digital time capsules. Explore the benefits of self-encouragement and positive thinking." />
            </Helmet>
            <div className="container">
                <div className="flex md:flex-row flex-col gap-[40px] justify-between items-center">
                    <div className="lg:w-1/2 w-full">
                        <h4 className='uppercase mb-[15px] text-[#FD6F35] text-lg'>About us</h4>
                        <h2 className='text-white md:text-[40px] text-[30px] lh-40 font-[500]'>
                            Welcome to <br /><span className='text-[#FD6F35] font-[500]'>TimeCapsule Email</span>
                        </h2>
                        <p className='text-white mt-[12px] mb-[27px]'>
                            Fostering self-reflection, personal growth, and mental health. These digital time capsules provide self-encouragement, act as a therapeutic outlet, and support positive thinking through mindfulness and self-awareness.
                        </p>
                    </div>
                    <div className="lg:w-1/2 w-full">
                        <img src={man} className='w-full' alt="TimeCapsule Email - About Us" />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ImageColumn;
